import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Video from './Video';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
	<Routes>
	  <Route path="/comp5123/video" element={<Video />}>
	  </Route>
	</Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
