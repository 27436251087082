import React from 'react';

const Video = () => {
	return (
		<div style={{ margin: "0px auto", width: "750px", height: "500px"}}>
			<iframe width="750" height="500"
				title='video'
				src="https://www.youtube.com/embed/YQtM64JnGaU?modestbranding=1&fs=0&showinfo=0&rel=0&controls=1">
			</iframe>
		</div>
	);
}

export default Video;